import { ref, toRefs } from 'vue';
import { useFirebaseApp } from 'vuefire';
import { getMessaging, getToken, deleteToken } from 'firebase/messaging';

import { defineStore, acceptHMRUpdate } from 'pinia';
import { useAuthStore } from '@stores/useAuthStore';
import { useUrqlStore } from '@stores/useUrqlStore';
import { ADD_FIREBASE_APP_TOKEN_MUTATION, REMOVE_FIREBASE_APP_TOKEN_MUTATION } from '@graphql/mutations/notifications';

import type { AddFirebaseAppTokenResponse, RemoveFirebaseAppTokenResponse } from '@graphql/types/notifications';

export const storeSetup = () => {
  const { gqlClient } = toRefs(useUrqlStore());

  // State
  let stopListeningEventHandler: undefined | (() => void);
  const deviceToken = ref<string | undefined>(undefined);
  const notifications = ref([]);
  const notificationRoute = ref<string | undefined>(undefined);

  // Actions
  const registerNotifications = async () => {
    const messaging = getMessaging(useFirebaseApp());

    try {
      // requestPermission to show notifications
      const token = await getToken(messaging);

      if (token) deviceToken.value = token;
    } catch (error) {
      console.error('Error getting FCM token:', error);
    }
  };

  const unregisterNotifications = async () => {
    if (deviceToken.value) {
      await gqlClient.value.mutation<RemoveFirebaseAppTokenResponse>(REMOVE_FIREBASE_APP_TOKEN_MUTATION, {
        input: { token: deviceToken.value },
      }).toPromise();

      const messaging = getMessaging(useFirebaseApp());
      deleteToken(messaging);

      deviceToken.value = undefined;

      if (stopListeningEventHandler) stopListeningEventHandler();
    }
  };

  const registerFcmToken = async () => {
    await registerNotifications();

    if (deviceToken.value) {
      await gqlClient.value.mutation<AddFirebaseAppTokenResponse>(ADD_FIREBASE_APP_TOKEN_MUTATION, {
        input: { token: deviceToken.value, environment: 'mobile' },
      }).toPromise();
    }
  };

  const isTokenValid = () => {
    const { user } = toRefs(useAuthStore());

    return user.value?.fireBaseAppTokens?.find(token => token?.token === deviceToken.value)?.token;
  };

  const checkForFcmToken = async () => {
    if (deviceToken.value && isTokenValid()) {
      return;
    }

    await registerFcmToken();
  };

  const setNotificationRoute = (route: string | undefined) => {
    notificationRoute.value = route;
  };

  return {
    // State
    deviceToken,
    notifications,
    notificationRoute,

    // Actions
    checkForFcmToken,
    registerNotifications,
    unregisterNotifications,
    setNotificationRoute,
  };
};

export const useNotificationStore = defineStore('notification', storeSetup, {
  persistedState: {
    storage: localStorage,
    merge: (state, savedState) => {
      state.deviceToken = savedState.deviceToken;

      return state;
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNotificationStore, import.meta.hot));
}
