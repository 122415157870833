export enum MobileOs {
  IOS = 'IOS',
  ANDROID = 'ANDROID',
}

export enum BiometricSensor {
  FINGERPRINT = 'FINGERPRINT',
  FACE_ID = 'FACE_ID',
  NONE = 'NONE',
}
