import { defineStore } from 'pinia';
import { toRefs } from 'vue';
import { createClient, fetchExchange } from '@urql/core';

import { useAuthStore } from '@stores/useAuthStore';
import { isIosOrAndroid } from '@helpers/mobile';
import { cacheExchange } from '@urql/exchange-graphcache';
import { offlineExchange } from '@urql/exchange-graphcache';
import { makeDefaultStorage } from '@urql/exchange-graphcache/default-storage';

export const storeSetup = () => {
  const { accessToken } = toRefs(useAuthStore());

  const storage = makeDefaultStorage({
    idbName: 'graphcache-v3',
    maxAge: 7, // The maximum age of the persisted data in days
  });
  
  const cache = offlineExchange({
    storage,
    updates: {
      /* ... */
    },
    optimistic: {
      /* ... */
    },
  });

  const gqlClient = createClient({
    url: import.meta.env.VITE_PROXY_URL ?? '',
    requestPolicy: 'cache-and-network',
    fetchOptions: () => ({
      headers: {
        authorization: `Bearer ${accessToken?.value}`,
        role: 'investor',
        ispwa: isIosOrAndroid().toString(),
        environment: import.meta.env.VITE_GRAPHQL_ENVIRONMENT,
      },
    }),
    exchanges: [
      cache,
      fetchExchange,
      cacheExchange({
        resolvers: {
          Query: {
            stats: (parent, args) => ({ __typename: "CreditorStats", ...args }),
            me: (parent, args) => ({ __typename: "Person", ...args }),
          },
        },
        updates: {
          Query: {
            Objects: () => {
              // Do not return a cached response from the urql cache on this query
              return null;
            },
            onboardingTemplate: () => {
              return null;
            }
          },
        },
      }),
    ],
  });

  return {
    gqlClient,
  };
};

export const useUrqlStore = defineStore('urql', storeSetup, { persistedState: { persist: false } });
