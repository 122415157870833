import payload_plugin_NEx9oIfTEL from "/shared/home/frontend/deployr/releases/20250403135050/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_xKuw1km5sV from "/shared/home/frontend/deployr/releases/20250403135050/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_client_V4UTG2qgGR from "/shared/home/frontend/deployr/releases/20250403135050/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import plugin_client_9L1MKQjYBH from "/shared/home/frontend/deployr/releases/20250403135050/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.js";
import vuefire_plugin_8cq4FnzXKb from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/.nuxt/vuefire-plugin.mjs";
import plugin_ZbXcQ8JydQ from "/shared/home/frontend/deployr/releases/20250403135050/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import persistedstate_universal_Q3SrNsmaVW from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/plugins/persistedstate.universal.ts";
import laravel_echo_skpHYtYD88 from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/plugins/laravel-echo.ts";
import fontAwesome_ZfwYhjRo2I from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/plugins/fontAwesome.js";
import maska_UHaKf2z1iQ from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/plugins/maska.ts";
import service_worker_9hnzxsdKJj from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/plugins/service-worker.js";
import sharedstate_universal_KWEDR1ZWki from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/plugins/sharedstate.universal.ts";
import smartsupp_client_HUImH5w65Z from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/plugins/smartsupp.client.ts";
import urql_FlK1jaV38U from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/plugins/urql.ts";
export default [
  payload_plugin_NEx9oIfTEL,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  plugin_vue3_xKuw1km5sV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  pwa_client_V4UTG2qgGR,
  plugin_client_9L1MKQjYBH,
  vuefire_plugin_8cq4FnzXKb,
  plugin_ZbXcQ8JydQ,
  chunk_reload_client_UciE0i6zes,
  persistedstate_universal_Q3SrNsmaVW,
  laravel_echo_skpHYtYD88,
  fontAwesome_ZfwYhjRo2I,
  maska_UHaKf2z1iQ,
  service_worker_9hnzxsdKJj,
  sharedstate_universal_KWEDR1ZWki,
  smartsupp_client_HUImH5w65Z,
  urql_FlK1jaV38U
]