import { gql } from '@urql/core';

export const UPDATE_NOTIFICATION_PREFERENCE = gql`
  mutation ($enabled: Boolean!, $type: String!) {
    appNotificationPreference(enabled: $enabled, type: $type) {
      enabled
      title
      type
      __typename
    }
  }
`;

export const ADD_FIREBASE_APP_TOKEN_MUTATION = gql`
  mutation AddFirebaseAppToken($input: FirebaseAppTokenInput) {
    addFirebaseAppToken(input: $input) {
      status
      message
      __typename
    }
  }
`;

export const REMOVE_FIREBASE_APP_TOKEN_MUTATION = gql`
  mutation RemoveFirebaseAppToken($input: FirebaseAppTokenInput) {
    removeFirebaseAppToken(input: $input) {
      status
      message
      __typename
    }
  }
`;
