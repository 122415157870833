import { defineStore, acceptHMRUpdate } from 'pinia';
import { computed, ref } from 'vue';
import { ME_QUERY, INITIAL_PERSON_QUERY } from '@graphql/queries/auth';
import { Person, MeResponse, Company, PersonRoles, Account } from '@graphql/types/auth';
import { FundStatuses } from '@graphql/enums/fundStatuses';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { PersonRoleTypeEnum, RoleStatusEnum } from '@graphql/enums/auth';
import { createClient, OperationResult, ClientOptions, fetchExchange, cacheExchange } from '@urql/core';
import { OnfidoStatusEnum } from '@graphql/enums/onfidoStatus';
import { isIosOrAndroid } from '@helpers/mobile';
// eslint-disable-next-line import/no-cycle
import { useNotificationStore } from '@stores/useNotificationStore';
import { usePinStore } from '@stores/usePinStore';
import { makeDefaultStorage } from '@urql/exchange-graphcache/default-storage';
import { offlineExchange } from '@urql/exchange-graphcache';

const storage = makeDefaultStorage({
  idbName: 'graphcache-v3-auth',
  maxAge: 1, // The maximum age of the persisted data in days
});

const cache = offlineExchange({
  storage,
  updates: {
    /* ... */
  },
  optimistic: {
    /* ... */
  },
});

const clientOptions: ClientOptions = {
  url: import.meta.env.VITE_PROXY_URL ?? '',
  requestPolicy: 'cache-and-network',
  fetchOptions: {
    headers: {
      ispwa: isIosOrAndroid().toString(),
      environment: import.meta.env.VITE_GRAPHQL_ENVIRONMENT,
    },
  },
  exchanges: [cache, fetchExchange, cacheExchange],
};

const gqlClient = createClient(clientOptions);

const storeSetup = () => {
  const user = ref<Person | null>(null);
  const accessToken = ref<string | null>(null);
  const refreshToken = ref<string | null>(null);
  const expiration = ref<number | null>(null);
  const activeFunderId = ref<number | string>(0);
  const isLoading = ref<boolean>(true);
  const isFromApp = ref<boolean>(false);
  const accounts = ref<{[key: string]: Account}>({});
  const selectedAccount = ref<string | null>(null);
  const isTourActive = ref<boolean>(false);

  const authenticated = computed(() => !isLoading.value && user.value !== null);
  const funders = computed(() => user.value?.roles ?? []);
  const fundInvestorStatus = computed(() => {
    const fundInvestor = user.value?.roles.find(
      role => role.type === PersonRoleTypeEnum.FUND_INVESTOR,
    );
    return fundInvestor?.status as FundStatuses | undefined;
  });

  const isInvestor = computed(() => {
    const investorDetails = user?.value?.roles.find(role => role.type === PersonRoleTypeEnum.INVESTOR);
    return investorDetails?.status === RoleStatusEnum.ACTIVE;
  });

  const isFundInvestor = computed(() => {
    const investor = user.value?.roles.find(role => role.type === PersonRoleTypeEnum.FUND_INVESTOR);
    return investor?.status === RoleStatusEnum.ACTIVE;
  });

  const isBorrower = computed(() => {
    const borrowerDetails = user?.value?.roles.find(role => role.type === PersonRoleTypeEnum.BORROWER);
    return borrowerDetails?.status === RoleStatusEnum.ACTIVE;
  });

  const onfidoChecked = computed(() => user.value?.onfido_checked);

  const isShowingInvestorAvIdGuardedRoutes = computed(() => isInvestor.value && Boolean(user.value?.roles.find(role => role.type === PersonRoleTypeEnum.INVESTOR && role.account_view_ids.length > 0)));
  const isShowingBorrowerAvIdGuardedRoutes = computed(() => isBorrower.value && Boolean(user.value?.roles.find(role => role.type === PersonRoleTypeEnum.BORROWER && role.account_view_ids.length > 0)));

  const shouldRefreshToken = computed((): boolean => {
    if (!refreshToken.value) return false;

    if (typeof accessToken.value === 'string') {
      const jwt = jwtDecode<JwtPayload>(accessToken.value);

      if (jwt?.exp && jwt?.exp < Date.now()) return false;
    }

    return true;
  });

  const addOrUpdateAccount = (account: Account) => {
    accounts.value = {
      ...accounts.value,
      [account.email]: account,
    };
  };

  const removeAccount = (email: string) => {
    accounts.value = Object.fromEntries(
      Object.values(accounts.value)
        .filter(account => account.email !== email)
        .map(account => [account.email, account]),
    );
  };

  const updateTokens = (
    _accessToken: string | null,
    _refreshToken: string | null,
    _expiration: number | null,
  ) => {
    accessToken.value = _accessToken;
    refreshToken.value = _refreshToken;
    expiration.value = _expiration;
  };

  const initializeNotificationToken = () => {
    if (!isIosOrAndroid()) return;

    const { checkForFcmToken } = useNotificationStore();
    checkForFcmToken();
  };

  const invalidateNotificationToken = () => {
    if (!isIosOrAndroid()) return;

    const { unregisterNotifications } = useNotificationStore();
    unregisterNotifications();
  };

  const login = (
    _user: Person,
    _accessToken: string,
    _refreshToken: string,
    _expiration: number,
  ) => {
    const { showPincodeOverlay } = usePinStore();

    user.value = _user;
    updateTokens(_accessToken, _refreshToken, _expiration);
    initializeNotificationToken();
    showPincodeOverlay();
  };

  const logout = () => {
    user.value = null;
    invalidateNotificationToken();
    updateTokens(null, null, null);
  };

  const setFunder = (id: number) => {
    activeFunderId.value = id;
  };

  const setIsFromApp = () => {
    isFromApp.value = true;
  };

  const setOnfidoStatusToDone = () => {
    if (user.value) {
      user.value.onfido_status = OnfidoStatusEnum.SUCCESS;
      user.value.onfido_checked = true;
    }
  };

  const setHasPincode = (value: boolean) => {
    if (user.value) {
      user.value.has_pincode = value;
    }
  };

  const queryInitialUser = async () => {
    if (!accessToken.value) {
      isLoading.value = false;
      return;
    }

    return gqlClient
      .query<MeResponse>(INITIAL_PERSON_QUERY, undefined, {
        requestPolicy: 'cache-and-network',
        fetchOptions: {
          headers: {
            ispwa: isIosOrAndroid().toString(),
            authorization: `Bearer ${accessToken.value}`,
            environment: import.meta.env.VITE_GRAPHQL_ENVIRONMENT,
          },
        },
      })
      .toPromise()
      .then(async (result: OperationResult<MeResponse>) => {
        if (result.data?.me) {
          Object.assign(user.value, result.data.me);
        } else {
          accessToken.value = null;
          refreshToken.value = null;
          expiration.value = null;
        }
      })
      .finally(() => {
        isLoading.value = false
      })
      .catch(() => {
        accessToken.value = null;
        refreshToken.value = null;
        expiration.value = null;
      });
  };

  const refreshUser = async () => {
    const { showPincodeOverlay } = usePinStore();

    return gqlClient
      .query<MeResponse>(ME_QUERY, undefined, {
        requestPolicy: 'cache-and-network',
        fetchOptions: {
          headers: {
            ispwa: isIosOrAndroid().toString(),
            authorization: `Bearer ${accessToken.value}`,
            environment: import.meta.env.VITE_GRAPHQL_ENVIRONMENT,
          },
        },
      })
      .toPromise()
      .then(async (result: OperationResult<MeResponse>) => {
        if (result.data?.me) {
          Object.assign(user.value, result.data.me);
          initializeNotificationToken();
          showPincodeOverlay();
        } else {
          accessToken.value = null;
          refreshToken.value = null;
          expiration.value = null;
        }
      })
      .finally(() => {
        isLoading.value = false;
      })
      .catch(() => {
        accessToken.value = null;
        refreshToken.value = null;
        expiration.value = null;
      });
  };

  const nawData = computed(() => {
    const mainAddress = user.value?.addresses.find(adress => adress.main_address === true);
    const initialRole = user.value?.roles.find(role => role.type === PersonRoleTypeEnum.INITIAL_PERSON);

    return {
      zipcode: mainAddress?.postal_code ?? '',
      house_number: mainAddress?.house_number ?? '',
      addition: mainAddress?.house_number_addition ?? '',
      city: mainAddress?.city ?? '',
      street: mainAddress?.street ?? '',
      phone_numbers: initialRole?.role_data.phones ?? [],
      emails: initialRole?.role_data.emails ?? [],
    };
  });

  const nawCompleted = computed(() => {
    return nawData.value.zipcode.length > 0
      && nawData.value.house_number.length > 0
      && nawData.value.city.length > 0
      && nawData.value.street.length > 0
      && nawData.value.phone_numbers.length > 0
      && nawData.value.emails.length > 0;
  });

  const companies = computed(() => {
    return user.value?.companies;
  });

  const hasIbans = computed(() => {
    let hasPersonalIbans = false;
    user.value?.roles.forEach((role: PersonRoles) => {
      if (role.role_data.ibans) {
        role.role_data.ibans.forEach(_iban => {
          hasPersonalIbans = true;
        });
      }
    });
    return hasPersonalIbans;
  });

  const myProfileNotificationCount = computed(() => {
    const array = [nawCompleted.value, hasIbans.value, onfidoChecked.value];
    return (array.length - array.filter(Boolean).length);
  });

  const getUserPhoneNumbers = () => user.value?.roles.filter(role => role.role_data.phones?.length > 0).map(role => role.role_data.phones[0].phone);

  const getPersonRole = (type: PersonRoleTypeEnum) => user.value?.roles.find(role => role.type === type);

  const getCompanyById = (id: string) => user?.value?.companies?.find((company: Company) => company?.id === id);

  const isVisibleForInvestorInApp = computed(() => (isInvestor.value && (getPersonRole(PersonRoleTypeEnum.INVESTOR)?.account_view_ids?.length ?? 0) > 0));
  const isVisibleForBorrowerInApp = computed(() => (isBorrower.value && (getPersonRole(PersonRoleTypeEnum.BORROWER)?.account_view_ids?.length ?? 0) > 0));

  return {
    // State
    user,
    accessToken,
    refreshToken,
    expiration,
    activeFunderId,
    isLoading,
    isFromApp,
    accounts,
    selectedAccount,
    isTourActive,

    // Getters
    authenticated,
    funders,
    fundInvestorStatus,
    isFundInvestor,
    isInvestor,
    isBorrower,
    shouldRefreshToken,
    getUserPhoneNumbers,
    getPersonRole,
    myProfileNotificationCount,
    hasIbans,
    nawCompleted,
    companies,
    nawData,
    onfidoChecked,
    setOnfidoStatusToDone,
    isVisibleForInvestorInApp,
    isVisibleForBorrowerInApp,
    isShowingInvestorAvIdGuardedRoutes,
    isShowingBorrowerAvIdGuardedRoutes,
    queryInitialUser,

    // Actions
    login,
    logout,
    setFunder,
    updateTokens,
    refreshUser,
    getCompanyById,
    setIsFromApp,
    addOrUpdateAccount,
    removeAccount,
    setHasPincode,
  };
};

export const useAuthStore = defineStore('auth', storeSetup, {
  persistedState: {
    merge: (state, savedState) => {
      if (savedState.accessToken) {
        gqlClient
          .query<MeResponse>(INITIAL_PERSON_QUERY, undefined, {
            requestPolicy: 'cache-and-network',
            fetchOptions: {
              headers: {
                ispwa: isIosOrAndroid().toString(),
                authorization: `Bearer ${savedState.accessToken}`,
                environment: import.meta.env.VITE_GRAPHQL_ENVIRONMENT,
              },
            },
          })
          .toPromise()
          .then((result: OperationResult<MeResponse>) => {
            if (result.data?.me) {
              state.user = result.data.me;

              if (!isIosOrAndroid()) return;

              const { checkForFcmToken } = useNotificationStore();
              const { showPincodeOverlay } = usePinStore();

              showPincodeOverlay();
              checkForFcmToken();
            } else {
              state.accessToken = null;
              state.refreshToken = null;
              state.expiration = null;
              state.activeFunderId = 0;
            }
          })
          .catch(() => {
            state.accessToken = null;
            state.refreshToken = null;
            state.expiration = null;
            state.activeFunderId = 0;
          })
          .finally(() => {
            state.isLoading = false;
          });
          
        gqlClient
          .query<MeResponse>(ME_QUERY, undefined, {
            requestPolicy: 'cache-and-network',
            fetchOptions: {
              headers: {
                ispwa: isIosOrAndroid().toString(),
                authorization: `Bearer ${savedState.accessToken}`,
                environment: import.meta.env.VITE_GRAPHQL_ENVIRONMENT,
              },
            },
          })
          .toPromise()
          .then((result: OperationResult<MeResponse>) => {
            if (result.data?.me) {
              state.user = result.data.me;

              if (!isIosOrAndroid()) return;

              const { checkForFcmToken } = useNotificationStore();
              const { showPincodeOverlay } = usePinStore();

              showPincodeOverlay();
              checkForFcmToken();
            } else {
              state.accessToken = null;
              state.refreshToken = null;
              state.expiration = null;
              state.activeFunderId = 0;
            }
          })
          .catch(() => {
            state.accessToken = null;
            state.refreshToken = null;
            state.expiration = null;
            state.activeFunderId = 0;
          })
          .finally(() => {
            state.isLoading = false;
          });
      }
      state.accessToken = savedState.accessToken ?? null;
      state.refreshToken = savedState.refreshToken ?? null;
      state.expiration = savedState.expiration ?? null;
      state.activeFunderId = savedState.activeFunderId ?? 0;
      state.accounts = savedState.accounts ?? {};
      state.selectedAccount = savedState.selectedAccount ?? null;

      return state;
    },
    excludePaths: ['user', 'isFromApp'],
  },
  share: {
    initialize: true,
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
}
