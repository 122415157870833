<script setup lang="ts">
import 'shepherd.js/dist/css/shepherd.css';
import '@mogelijk-technologies/styles/src/index.css';
import '@mogelijk-technologies/ui-library/dist/style.css';
import '@/assets/css/style.css';

import { onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import { getMessaging, onMessage } from 'firebase/messaging';
import { useAuthStore } from '@stores/useAuthStore';
import { useNotificationStore } from '@stores/useNotificationStore';
import { isIosOrAndroid } from '@helpers/mobile';

const router = useRouter();
const { setNotificationRoute, checkForFcmToken } = useNotificationStore();
const { authenticated } = toRefs(useAuthStore());
const { $pwa } = useNuxtApp();

if (isIosOrAndroid()) {
  const messaging = getMessaging(useFirebaseApp());

  const stopListeningEventHandler = onMessage(messaging, payload => {
    if (payload.notification) {
      const { title = 'Mijn Mogelijk', body } = payload.notification;
      const notification = new Notification(title, { body });

      notification.addEventListener('click', async () => {
        let fullRoute = '/';

        if (payload.data?.action === 'tabs.investor.claimsAndOptions' && payload.data?.propertyId) {
          fullRoute = `/claims-en-opties?id=${payload.data.propertyId}`;
        } else if (payload.data?.action === 'tabs.investor.investments') {
          fullRoute = '/investeringen';
        }

        setNotificationRoute(fullRoute);
        router.push(fullRoute);
        notification.close();
      });
    }
  });

  onUnmounted(stopListeningEventHandler);
}

onMounted(async () => {
  if (isIosOrAndroid() && authenticated.value) {
    await Notification.requestPermission();
    checkForFcmToken();
  }
});
</script>

<template>
  <PwaInstallPrompt v-if="$pwa?.showInstallPrompt" />
  <NuxtPage />
</template>
