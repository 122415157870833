import { defineStore, acceptHMRUpdate } from 'pinia';
import { ref } from 'vue';
import { BiometricSensor, MobileOs } from '@graphql/enums/mobile';
import { useToast } from '@mogelijk-technologies/ui-library';
import { getMobileOs } from '@helpers/mobile';
import { useAuthStore } from '@stores/useAuthStore';

const storeSetup = () => {
  // State
  const firstCode = ref('');
  const secondCode = ref('');
  const secondsTillLock = ref(120);
  const isPincodeVisible = ref(false);
  const isUsingBiometrics = ref(false);
  const isResettingPincode = ref(false);
  const isDisablingPincode = ref(false);
  const isUsingBiometricSensor = ref(false);
  const credentialId = ref<string | null>(null);
  const challenge = ref<string | null>(null);

  const mobileOs = computed(() => getMobileOs());

  const biometricSensor = computed(() => {
    if (mobileOs.value === MobileOs.IOS) {
      return /iPhone/.test(navigator.userAgent) && window.screen.height >= 812 ? BiometricSensor.FACE_ID : BiometricSensor.FINGERPRINT;
    }

    if (mobileOs.value === MobileOs.ANDROID) return BiometricSensor.FINGERPRINT;

    return BiometricSensor.NONE;
  });

  const isBiometricSensorAvailable = computed(() => {
    if (mobileOs.value === MobileOs.IOS) return !!window.PublicKeyCredential;

    if (mobileOs.value === MobileOs.ANDROID) return !!(window.PublicKeyCredential && navigator.credentials?.create);

    return false;
  });

  // Actions
  const setFirstCode = (value: string) => { firstCode.value = value; };
  const setSecondCode = (value: string) => { secondCode.value = value; };
  const setSecondsTillLock = (value: number) => { secondsTillLock.value = value; };
  const setIsUsingBiometrics = (value: boolean) => { isUsingBiometrics.value = value; };
  const setIsPincodeVisible = (value: boolean) => { isPincodeVisible.value = value; };
  const setIsResettingPincode = (value: boolean) => { isResettingPincode.value = value; };
  const setIsDisablingPincode = (value: boolean) => { isDisablingPincode.value = value; };

  const toBase64 = (uint8Array: Uint8Array) => btoa(String.fromCodePoint(...uint8Array));
  const fromBase64 = (base64: string) => [...atob(base64)].map(char => char.codePointAt(0) as number);

  const showErrorMessage = async (message?: string) => {
    const toast = await useToast({
      title: 'Fout',
      message: message ?? 'Authenticatie mislukt, probeer het opnieuw.',
      theme: 'error',
    });

    toast.present();
  };

  const createAuthenticationCredential = async () => {
    const { user } = toRefs(useAuthStore());

    if (!user.value) return;

    try {
      isUsingBiometricSensor.value = true;
      challenge.value = toBase64(new Uint8Array(crypto.getRandomValues(new Uint8Array(32))));

      const publicKey = await navigator.credentials.create({
        publicKey: {
          challenge: new Uint8Array(fromBase64(challenge.value)),
          rp: { name: 'Mijn Mogelijk' },
          user: { id: new TextEncoder().encode(user.value.id), name: user.value.first_name, displayName: user.value.name },
          pubKeyCredParams: [{ type: 'public-key', alg: -7 }],
          authenticatorSelection: { authenticatorAttachment: 'platform' },
          timeout: 60_000,
          attestation: 'none',
        },
      });

      credentialId.value = toBase64(new Uint8Array((publicKey as PublicKeyCredential)?.rawId));
    } catch {
      setIsUsingBiometrics(false);

      showErrorMessage('Biometrische authenticatie is niet beschikbaar op dit apparaat.');
    } finally {
      isUsingBiometricSensor.value = false;
    }
  };

  const disableScroll = (event: Event) => event.preventDefault();

  const hidePincodeOverlay = () => {
    setIsPincodeVisible(false);
    document.body.style.overflow = 'auto';

    window.removeEventListener('wheel', disableScroll);
    window.removeEventListener('touchmove', disableScroll);
  };

  const requestBiometricAuthentication = async () => {
    if (!credentialId.value || !challenge.value) return;

    try {
      isUsingBiometricSensor.value = true;

      const isAuthenticated = await navigator.credentials.get({
        publicKey: {
          challenge: new Uint8Array(fromBase64(challenge.value)),
          allowCredentials: [{ id: new Uint8Array(fromBase64(credentialId.value)), type: 'public-key' }],
          timeout: 60_000,
          userVerification: 'required',
        },
      });

      if (isAuthenticated) {
        hidePincodeOverlay();
      } else {
        showErrorMessage('Ongeldige authenticatie, probeer het opnieuw.');
      }
    } catch (error) {
      if (error && !error.toString().includes('operation either timed out or was not allowed')) {
        showErrorMessage('De authenticatie is mislukt, probeer het opnieuw.');
      }

      if (!isPincodeVisible.value) {
        setIsPincodeVisible(true);
        document.body.style.overflow = 'hidden';
      }
    } finally {
      isUsingBiometricSensor.value = false;
    }
  };

  const showPincodeOverlay = (isEnablingPincode = false) => {
    const { user } = toRefs(useAuthStore());

    if (isPincodeVisible.value || isUsingBiometricSensor.value || (!user.value?.has_pincode && !isEnablingPincode)) return;

    document.body.style.overflow = 'hidden';
    window.addEventListener('wheel', disableScroll, { passive: false });
    window.addEventListener('touchmove', disableScroll, { passive: false });

    setIsPincodeVisible(true);

    if (isUsingBiometrics.value && !isDisablingPincode.value && !isResettingPincode.value) {
      requestBiometricAuthentication();
    }
  };

  return {
    // State
    firstCode,
    secondCode,
    secondsTillLock,
    isPincodeVisible,
    isUsingBiometrics,
    isBiometricSensorAvailable,
    isResettingPincode,
    isDisablingPincode,
    biometricSensor,
    mobileOs,
    credentialId,
    challenge,

    // Actions
    setFirstCode,
    setSecondCode,
    setSecondsTillLock,
    setIsUsingBiometrics,
    setIsPincodeVisible,
    setIsResettingPincode,
    setIsDisablingPincode,

    createAuthenticationCredential,
    requestBiometricAuthentication,
    showPincodeOverlay,
    hidePincodeOverlay,
  };
};

export const usePinStore = defineStore('pin', storeSetup, {
  persistedState: {
    merge: (state, savedState) => {
      state.isUsingBiometrics = savedState.isUsingBiometrics ?? false;
      state.secondsTillLock = savedState.secondsTillLock ?? false;
      state.credentialId = savedState.credentialId ?? null;
      state.challenge = savedState.challenge ?? null;

      return state;
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePinStore, import.meta.hot));
}
