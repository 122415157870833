import { gql } from '@urql/core';

export const ME_QUERY = gql`
  query Me {
    me {
      id
      name
      first_name
      last_name
      infix
      gender
      date_of_birth
      place_of_birth
      country_of_birth
      onfido_checked
      onfido_status
      onfido_hash
      is_mogelijk_partner
      has_pincode
      bypass_checks
      addresses {
        main_address
        street
        house_number
        house_number_addition
        postal_code
        city
        __typename
      }
      fireBaseAppTokens {
        ... on FirebaseAppToken {
          id
          device_name
          token
          __typename
        }
        ... on MobileFirebaseAppToken {
          id
          token
          device_name
          __typename
        }
        __typename
      }
      documents {
        id
        key
        name
        type
        visible_for_customer
        onfido_status
        records {
          asset {
            absolute_url
            created_at
            extension
            filename
            id
            updated_at
            filesize {
              raw
              human
              __typename
            }
            __typename
          }
          created_at
          id
          name
          updated_at
          downloadable_for_customer
          __typename
        }
        __typename
      }
      documentContext {
        documentOwners {
          owner {
            ... on Person {
              id
              name
              __typename
            }
            ... on Company {
              id
              name
              coc_number
              __typename
            }
            __typename
          }
          items {
            id
            key
            name
            type
            visible_for_customer
            deletable_for_customer
            onfido_status
            maxSize
            template {
              absolute_url
              created_at
              extension
              filename
              id
              __typename
            }
            records {
              asset {
                absolute_url
                created_at
                extension
                filename
                id
                updated_at
                filesize {
                  raw
                  human
                  __typename
                }
                __typename
              }
              created_at
              id
              name
              updated_at
              downloadable_for_customer
              __typename
            }
            __typename
          }
          __typename
        }
        title
        fund_key
        show
        __typename
      }
      roles {
        id
        type
        status
        account_view_ids
        role_data {
          ... on InitialPerson {
            ibans
            emails {
              email
              type
              __typename
            }
            phones {
              phone
              type
              __typename
            }
            __typename
          }
          ... on Investor {
            ibans
            emails {
              email
              type
              __typename
            }
            phones {
              phone
              type
              __typename
            }
            __typename
          }
          ... on FundInvestor {
            ibans
            emails {
              email
              type
              __typename
            }
            phones {
              phone
              type
              __typename
            }
            __typename
          }
          ... on Borrower {
            ibans
            emails {
              email
              type
              __typename
            }
            phones {
              phone
              type
              __typename
            }
            __typename
          }
          ... on Advisor {
            ibans
            emails {
              email
              type
              __typename
            }
            phones {
              phone
              type
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      companies {
        id
        status
        name
        type
        coc_number
        ibans
        ubo_count
        __typename
      }
      funders {
        id
        status
        name
        type
        coc_number
        ibans
        ubo_count
        __typename
      }
      __typename
    }
    __typename
  }
`;

export const INITIAL_PERSON_QUERY = gql`
  query Me {
    me {
      id
      name
      first_name
      last_name
      infix
      onfido_checked
      onfido_status
      is_mogelijk_partner
      has_pincode
      bypass_checks
      addresses {
        main_address
        street
        house_number
        house_number_addition
        postal_code
        city
        __typename
      }
      roles {
        id
        type
        status
        account_view_ids
        role_data {
          ... on InitialPerson {
            ibans
            emails {
              email
              type
              __typename
            }
            phones {
              phone
              type
              __typename
            }
            __typename
          }
          ... on Investor {
            ibans
            emails {
              email
              type
              __typename
            }
            phones {
              phone
              type
              __typename
            }
            __typename
          }
          ... on FundInvestor {
            ibans
            emails {
              email
              type
              __typename
            }
            phones {
              phone
              type
              __typename
            }
            __typename
          }
          ... on Borrower {
            ibans
            emails {
              email
              type
              __typename
            }
            phones {
              phone
              type
              __typename
            }
            __typename
          }
          ... on Advisor {
            ibans
            emails {
              email
              type
              __typename
            }
            phones {
              phone
              type
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      funders {
        id
        status
        name
        type
        coc_number
        ibans
        ubo_count
        __typename
      }
      __typename
    }
    __typename
  }
`;