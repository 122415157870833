import { default as _91choice_93eED2G1hkynMeta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/aanbod-optie/[propertyId]/[hash]/[choice].vue?macro=true";
import { default as index8W9Nk5PhFaMeta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/aanbod/index.vue?macro=true";
import { default as indexwMLku6FFEHMeta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/claims-en-opties/index.vue?macro=true";
import { default as _91hash_93ixGXCY9NOsMeta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/einde-looptijd-lening/[hash].vue?macro=true";
import { default as indexU3qeMq1SL1Meta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/financieel-overzicht/financien/index.vue?macro=true";
import { default as indexByPHcAY5B0Meta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/financieel-overzicht/index.vue?macro=true";
import { default as indexts3yANIQVhMeta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/financieel-overzicht/rente/index.vue?macro=true";
import { default as indexSWZa6vmeQeMeta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/fonds-optie/[property_id]/[hash]/[type]/index.vue?macro=true";
import { default as indexbAGZEFEgriMeta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/hypotheek-overzicht/index.vue?macro=true";
import { default as index8HpsOKFTIeMeta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/hypotheken/[id]/index.vue?macro=true";
import { default as indexNxBaMJ6XDGMeta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/hypotheken/index.vue?macro=true";
import { default as indexhULKe1yx82Meta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/index.vue?macro=true";
import { default as index3FC4fkQYvJMeta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/instellingen/index.vue?macro=true";
import { default as indexOKKhFhMxtPMeta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/investeringen/[id]/index.vue?macro=true";
import { default as indexSWQ41FlTLNMeta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/investeringen/index.vue?macro=true";
import { default as indexJ4NJgdg9Y7Meta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/login/index.vue?macro=true";
import { default as reset_45wachtwoorduA4iipgf7iMeta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/login/reset-wachtwoord.vue?macro=true";
import { default as wachtwoord_45vergetenBHhf6pEF2IMeta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/login/wachtwoord-vergeten.vue?macro=true";
import { default as indexeQKaRzUXnsMeta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/mijn-aanvragen/[offerNumber]/herkomst/index.vue?macro=true";
import { default as index3Ns3mRtIEyMeta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/mijn-aanvragen/[offerNumber]/index.vue?macro=true";
import { default as indexVk4nrfuRNkMeta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/mijn-aanvragen/index.vue?macro=true";
import { default as indexC29T36Jvc3Meta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/mijn-organisaties/[companyId]/iban-overzicht/index.vue?macro=true";
import { default as index14KE3AvAJnMeta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/mijn-organisaties/bedrijfs-gegevens/index.vue?macro=true";
import { default as indexWBljkxo7kLMeta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/mijn-organisaties/index.vue?macro=true";
import { default as indexVlfb3vFDqEMeta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/mijn-partnerplan/[token]/[choice]/index.vue?macro=true";
import { default as indexu3Gjo6DN4sMeta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/mijn-partnerplan/index.vue?macro=true";
import { default as indexEnJYqybpvLMeta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/mijn-profiel/digitaal-identificeren/index.vue?macro=true";
import { default as indexKDtHpetaBFMeta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/mijn-profiel/iban-overzicht/index.vue?macro=true";
import { default as indexB6xl8CcoKrMeta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/mijn-profiel/index.vue?macro=true";
import { default as indexlLlMvofW2PMeta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/mijn-profiel/naw-gegevens/index.vue?macro=true";
import { default as indexhC5b3Pz5xtMeta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/mogelijk-fonds/[id]/deelname/index.vue?macro=true";
import { default as indexBqIcNiwhp6Meta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/mogelijk-fonds/[id]/documenten/index.vue?macro=true";
import { default as indexXk1Cvj7w7CMeta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/mogelijk-fonds/[id]/index.vue?macro=true";
import { default as indexyJjj97GU1jMeta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/mogelijk-fonds/[id]/inschrijving/index.vue?macro=true";
import { default as indexxY45ViPDnKMeta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/mogelijk-fonds/[id]/ondertekenen/index.vue?macro=true";
import { default as indexXcO8vXQcDGMeta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/mogelijk-fonds/[id]/overmaken/index.vue?macro=true";
import { default as index2rUv29cehyMeta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/mogelijk-fonds/index.vue?macro=true";
import { default as errorV94aXjeAt4Meta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/ockto/[hash]/error.vue?macro=true";
import { default as indexXE2fGpq6b3Meta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/ockto/[hash]/index.vue?macro=true";
import { default as qrcodefBsFCWO26gMeta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/ockto/[hash]/qrcode.vue?macro=true";
import { default as succesVIDiQ80mIhMeta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/ockto/[hash]/succes.vue?macro=true";
import { default as _91token_93wnVJg49iDRMeta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/onboarding/[signing_id]/[token].vue?macro=true";
import { default as _91slug_93TMdVpEilfTMeta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/onboarding/[slug].vue?macro=true";
import { default as aanvraagE6PhZ5QZ7dMeta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/onboarding/aanvraag.vue?macro=true";
import { default as _91experience_93dCXXeumJXmMeta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/onboarding/bedankt/[experience].vue?macro=true";
import { default as _91token_93kVj7tYES6YMeta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/onfido/[token].vue?macro=true";
import { default as _91hash_93e3AeVPAQXsMeta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/passeren/[hash].vue?macro=true";
import { default as indexHwHfFf4WDrMeta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/privacy-verklaring/index.vue?macro=true";
import { default as _91hash_93cOK3iYa7sEMeta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/rentevoorstel/[hash].vue?macro=true";
import { default as _91fundKey_93sBiu8yXXGeMeta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/sso/[exchangeToken]/[fundKey].vue?macro=true";
import { default as aanvraag3EB278SSkFMeta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/sso/[exchangeToken]/onboarding/aanvraag.vue?macro=true";
import { default as indexzgTcSifIEYMeta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/sso/[exchangeToken]/onboarding/index.vue?macro=true";
import { default as _91id_93gaSF8k5pn5Meta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/sso/[token]/[id].vue?macro=true";
import { default as indexKRagKrAcR8Meta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/sso/[token]/index.vue?macro=true";
import { default as _91hash_93a4ISHvH2iCMeta } from "/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/verzekeringen/[hash].vue?macro=true";
export default [
  {
    name: _91choice_93eED2G1hkynMeta?.name ?? "aanbod-optie-propertyId-hash-choice",
    path: _91choice_93eED2G1hkynMeta?.path ?? "/aanbod-optie/:propertyId()/:hash()/:choice()",
    meta: _91choice_93eED2G1hkynMeta || {},
    alias: _91choice_93eED2G1hkynMeta?.alias || [],
    redirect: _91choice_93eED2G1hkynMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/aanbod-optie/[propertyId]/[hash]/[choice].vue").then(m => m.default || m)
  },
  {
    name: index8W9Nk5PhFaMeta?.name ?? "aanbod",
    path: index8W9Nk5PhFaMeta?.path ?? "/aanbod",
    meta: index8W9Nk5PhFaMeta || {},
    alias: index8W9Nk5PhFaMeta?.alias || [],
    redirect: index8W9Nk5PhFaMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/aanbod/index.vue").then(m => m.default || m)
  },
  {
    name: indexwMLku6FFEHMeta?.name ?? "claims-en-opties",
    path: indexwMLku6FFEHMeta?.path ?? "/claims-en-opties",
    meta: indexwMLku6FFEHMeta || {},
    alias: indexwMLku6FFEHMeta?.alias || [],
    redirect: indexwMLku6FFEHMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/claims-en-opties/index.vue").then(m => m.default || m)
  },
  {
    name: _91hash_93ixGXCY9NOsMeta?.name ?? "einde-looptijd-lening-hash",
    path: _91hash_93ixGXCY9NOsMeta?.path ?? "/einde-looptijd-lening/:hash()",
    meta: _91hash_93ixGXCY9NOsMeta || {},
    alias: _91hash_93ixGXCY9NOsMeta?.alias || [],
    redirect: _91hash_93ixGXCY9NOsMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/einde-looptijd-lening/[hash].vue").then(m => m.default || m)
  },
  {
    name: indexU3qeMq1SL1Meta?.name ?? "financieel-overzicht-financien",
    path: indexU3qeMq1SL1Meta?.path ?? "/financieel-overzicht/financien",
    meta: indexU3qeMq1SL1Meta || {},
    alias: indexU3qeMq1SL1Meta?.alias || [],
    redirect: indexU3qeMq1SL1Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/financieel-overzicht/financien/index.vue").then(m => m.default || m)
  },
  {
    name: indexByPHcAY5B0Meta?.name ?? "financieel-overzicht",
    path: indexByPHcAY5B0Meta?.path ?? "/financieel-overzicht",
    meta: indexByPHcAY5B0Meta || {},
    alias: indexByPHcAY5B0Meta?.alias || [],
    redirect: indexByPHcAY5B0Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/financieel-overzicht/index.vue").then(m => m.default || m)
  },
  {
    name: indexts3yANIQVhMeta?.name ?? "financieel-overzicht-rente",
    path: indexts3yANIQVhMeta?.path ?? "/financieel-overzicht/rente",
    meta: indexts3yANIQVhMeta || {},
    alias: indexts3yANIQVhMeta?.alias || [],
    redirect: indexts3yANIQVhMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/financieel-overzicht/rente/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWZa6vmeQeMeta?.name ?? "fonds-optie-property_id-hash-type",
    path: indexSWZa6vmeQeMeta?.path ?? "/fonds-optie/:property_id()/:hash()/:type()",
    meta: indexSWZa6vmeQeMeta || {},
    alias: indexSWZa6vmeQeMeta?.alias || [],
    redirect: indexSWZa6vmeQeMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/fonds-optie/[property_id]/[hash]/[type]/index.vue").then(m => m.default || m)
  },
  {
    name: indexbAGZEFEgriMeta?.name ?? "hypotheek-overzicht",
    path: indexbAGZEFEgriMeta?.path ?? "/hypotheek-overzicht",
    meta: indexbAGZEFEgriMeta || {},
    alias: indexbAGZEFEgriMeta?.alias || [],
    redirect: indexbAGZEFEgriMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/hypotheek-overzicht/index.vue").then(m => m.default || m)
  },
  {
    name: index8HpsOKFTIeMeta?.name ?? "hypotheken-id",
    path: index8HpsOKFTIeMeta?.path ?? "/hypotheken/:id()",
    meta: index8HpsOKFTIeMeta || {},
    alias: index8HpsOKFTIeMeta?.alias || [],
    redirect: index8HpsOKFTIeMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/hypotheken/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexNxBaMJ6XDGMeta?.name ?? "hypotheken",
    path: indexNxBaMJ6XDGMeta?.path ?? "/hypotheken",
    meta: indexNxBaMJ6XDGMeta || {},
    alias: indexNxBaMJ6XDGMeta?.alias || [],
    redirect: indexNxBaMJ6XDGMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/hypotheken/index.vue").then(m => m.default || m)
  },
  {
    name: indexhULKe1yx82Meta?.name ?? "index",
    path: indexhULKe1yx82Meta?.path ?? "/",
    meta: indexhULKe1yx82Meta || {},
    alias: indexhULKe1yx82Meta?.alias || [],
    redirect: indexhULKe1yx82Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index3FC4fkQYvJMeta?.name ?? "instellingen",
    path: index3FC4fkQYvJMeta?.path ?? "/instellingen",
    meta: index3FC4fkQYvJMeta || {},
    alias: index3FC4fkQYvJMeta?.alias || [],
    redirect: index3FC4fkQYvJMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/instellingen/index.vue").then(m => m.default || m)
  },
  {
    name: indexOKKhFhMxtPMeta?.name ?? "investeringen-id",
    path: indexOKKhFhMxtPMeta?.path ?? "/investeringen/:id()",
    meta: indexOKKhFhMxtPMeta || {},
    alias: indexOKKhFhMxtPMeta?.alias || [],
    redirect: indexOKKhFhMxtPMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/investeringen/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWQ41FlTLNMeta?.name ?? "investeringen",
    path: indexSWQ41FlTLNMeta?.path ?? "/investeringen",
    meta: indexSWQ41FlTLNMeta || {},
    alias: indexSWQ41FlTLNMeta?.alias || [],
    redirect: indexSWQ41FlTLNMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/investeringen/index.vue").then(m => m.default || m)
  },
  {
    name: indexJ4NJgdg9Y7Meta?.name ?? "login",
    path: indexJ4NJgdg9Y7Meta?.path ?? "/login",
    meta: indexJ4NJgdg9Y7Meta || {},
    alias: indexJ4NJgdg9Y7Meta?.alias || [],
    redirect: indexJ4NJgdg9Y7Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: reset_45wachtwoorduA4iipgf7iMeta?.name ?? "login-reset-wachtwoord",
    path: reset_45wachtwoorduA4iipgf7iMeta?.path ?? "/login/reset-wachtwoord",
    meta: reset_45wachtwoorduA4iipgf7iMeta || {},
    alias: reset_45wachtwoorduA4iipgf7iMeta?.alias || [],
    redirect: reset_45wachtwoorduA4iipgf7iMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/login/reset-wachtwoord.vue").then(m => m.default || m)
  },
  {
    name: wachtwoord_45vergetenBHhf6pEF2IMeta?.name ?? "login-wachtwoord-vergeten",
    path: wachtwoord_45vergetenBHhf6pEF2IMeta?.path ?? "/login/wachtwoord-vergeten",
    meta: wachtwoord_45vergetenBHhf6pEF2IMeta || {},
    alias: wachtwoord_45vergetenBHhf6pEF2IMeta?.alias || [],
    redirect: wachtwoord_45vergetenBHhf6pEF2IMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/login/wachtwoord-vergeten.vue").then(m => m.default || m)
  },
  {
    name: indexeQKaRzUXnsMeta?.name ?? "mijn-aanvragen-offerNumber-herkomst",
    path: indexeQKaRzUXnsMeta?.path ?? "/mijn-aanvragen/:offerNumber()/herkomst",
    meta: indexeQKaRzUXnsMeta || {},
    alias: indexeQKaRzUXnsMeta?.alias || [],
    redirect: indexeQKaRzUXnsMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/mijn-aanvragen/[offerNumber]/herkomst/index.vue").then(m => m.default || m)
  },
  {
    name: index3Ns3mRtIEyMeta?.name ?? "mijn-aanvragen-offerNumber",
    path: index3Ns3mRtIEyMeta?.path ?? "/mijn-aanvragen/:offerNumber()",
    meta: index3Ns3mRtIEyMeta || {},
    alias: index3Ns3mRtIEyMeta?.alias || [],
    redirect: index3Ns3mRtIEyMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/mijn-aanvragen/[offerNumber]/index.vue").then(m => m.default || m)
  },
  {
    name: indexVk4nrfuRNkMeta?.name ?? "mijn-aanvragen",
    path: indexVk4nrfuRNkMeta?.path ?? "/mijn-aanvragen",
    meta: indexVk4nrfuRNkMeta || {},
    alias: indexVk4nrfuRNkMeta?.alias || [],
    redirect: indexVk4nrfuRNkMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/mijn-aanvragen/index.vue").then(m => m.default || m)
  },
  {
    name: indexC29T36Jvc3Meta?.name ?? "mijn-organisaties-companyId-iban-overzicht",
    path: indexC29T36Jvc3Meta?.path ?? "/mijn-organisaties/:companyId()/iban-overzicht",
    meta: indexC29T36Jvc3Meta || {},
    alias: indexC29T36Jvc3Meta?.alias || [],
    redirect: indexC29T36Jvc3Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/mijn-organisaties/[companyId]/iban-overzicht/index.vue").then(m => m.default || m)
  },
  {
    name: index14KE3AvAJnMeta?.name ?? "mijn-organisaties-bedrijfs-gegevens",
    path: index14KE3AvAJnMeta?.path ?? "/mijn-organisaties/bedrijfs-gegevens",
    meta: index14KE3AvAJnMeta || {},
    alias: index14KE3AvAJnMeta?.alias || [],
    redirect: index14KE3AvAJnMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/mijn-organisaties/bedrijfs-gegevens/index.vue").then(m => m.default || m)
  },
  {
    name: indexWBljkxo7kLMeta?.name ?? "mijn-organisaties",
    path: indexWBljkxo7kLMeta?.path ?? "/mijn-organisaties",
    meta: indexWBljkxo7kLMeta || {},
    alias: indexWBljkxo7kLMeta?.alias || [],
    redirect: indexWBljkxo7kLMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/mijn-organisaties/index.vue").then(m => m.default || m)
  },
  {
    name: indexVlfb3vFDqEMeta?.name ?? "mijn-partnerplan-token-choice",
    path: indexVlfb3vFDqEMeta?.path ?? "/mijn-partnerplan/:token()/:choice()",
    meta: indexVlfb3vFDqEMeta || {},
    alias: indexVlfb3vFDqEMeta?.alias || [],
    redirect: indexVlfb3vFDqEMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/mijn-partnerplan/[token]/[choice]/index.vue").then(m => m.default || m)
  },
  {
    name: indexu3Gjo6DN4sMeta?.name ?? "mijn-partnerplan",
    path: indexu3Gjo6DN4sMeta?.path ?? "/mijn-partnerplan",
    meta: indexu3Gjo6DN4sMeta || {},
    alias: indexu3Gjo6DN4sMeta?.alias || [],
    redirect: indexu3Gjo6DN4sMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/mijn-partnerplan/index.vue").then(m => m.default || m)
  },
  {
    name: indexEnJYqybpvLMeta?.name ?? "mijn-profiel-digitaal-identificeren",
    path: indexEnJYqybpvLMeta?.path ?? "/mijn-profiel/digitaal-identificeren",
    meta: indexEnJYqybpvLMeta || {},
    alias: indexEnJYqybpvLMeta?.alias || [],
    redirect: indexEnJYqybpvLMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/mijn-profiel/digitaal-identificeren/index.vue").then(m => m.default || m)
  },
  {
    name: indexKDtHpetaBFMeta?.name ?? "mijn-profiel-iban-overzicht",
    path: indexKDtHpetaBFMeta?.path ?? "/mijn-profiel/iban-overzicht",
    meta: indexKDtHpetaBFMeta || {},
    alias: indexKDtHpetaBFMeta?.alias || [],
    redirect: indexKDtHpetaBFMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/mijn-profiel/iban-overzicht/index.vue").then(m => m.default || m)
  },
  {
    name: indexB6xl8CcoKrMeta?.name ?? "mijn-profiel",
    path: indexB6xl8CcoKrMeta?.path ?? "/mijn-profiel",
    meta: indexB6xl8CcoKrMeta || {},
    alias: indexB6xl8CcoKrMeta?.alias || [],
    redirect: indexB6xl8CcoKrMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/mijn-profiel/index.vue").then(m => m.default || m)
  },
  {
    name: indexlLlMvofW2PMeta?.name ?? "mijn-profiel-naw-gegevens",
    path: indexlLlMvofW2PMeta?.path ?? "/mijn-profiel/naw-gegevens",
    meta: indexlLlMvofW2PMeta || {},
    alias: indexlLlMvofW2PMeta?.alias || [],
    redirect: indexlLlMvofW2PMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/mijn-profiel/naw-gegevens/index.vue").then(m => m.default || m)
  },
  {
    name: indexhC5b3Pz5xtMeta?.name ?? "mogelijk-fonds-id-deelname",
    path: indexhC5b3Pz5xtMeta?.path ?? "/mogelijk-fonds/:id()/deelname",
    meta: indexhC5b3Pz5xtMeta || {},
    alias: indexhC5b3Pz5xtMeta?.alias || [],
    redirect: indexhC5b3Pz5xtMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/mogelijk-fonds/[id]/deelname/index.vue").then(m => m.default || m)
  },
  {
    name: indexBqIcNiwhp6Meta?.name ?? "mogelijk-fonds-id-documenten",
    path: indexBqIcNiwhp6Meta?.path ?? "/mogelijk-fonds/:id()/documenten",
    meta: indexBqIcNiwhp6Meta || {},
    alias: indexBqIcNiwhp6Meta?.alias || [],
    redirect: indexBqIcNiwhp6Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/mogelijk-fonds/[id]/documenten/index.vue").then(m => m.default || m)
  },
  {
    name: indexXk1Cvj7w7CMeta?.name ?? "mogelijk-fonds-id",
    path: indexXk1Cvj7w7CMeta?.path ?? "/mogelijk-fonds/:id()",
    meta: indexXk1Cvj7w7CMeta || {},
    alias: indexXk1Cvj7w7CMeta?.alias || [],
    redirect: indexXk1Cvj7w7CMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/mogelijk-fonds/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexyJjj97GU1jMeta?.name ?? "mogelijk-fonds-id-inschrijving",
    path: indexyJjj97GU1jMeta?.path ?? "/mogelijk-fonds/:id()/inschrijving",
    meta: indexyJjj97GU1jMeta || {},
    alias: indexyJjj97GU1jMeta?.alias || [],
    redirect: indexyJjj97GU1jMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/mogelijk-fonds/[id]/inschrijving/index.vue").then(m => m.default || m)
  },
  {
    name: indexxY45ViPDnKMeta?.name ?? "mogelijk-fonds-id-ondertekenen",
    path: indexxY45ViPDnKMeta?.path ?? "/mogelijk-fonds/:id()/ondertekenen",
    meta: indexxY45ViPDnKMeta || {},
    alias: indexxY45ViPDnKMeta?.alias || [],
    redirect: indexxY45ViPDnKMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/mogelijk-fonds/[id]/ondertekenen/index.vue").then(m => m.default || m)
  },
  {
    name: indexXcO8vXQcDGMeta?.name ?? "mogelijk-fonds-id-overmaken",
    path: indexXcO8vXQcDGMeta?.path ?? "/mogelijk-fonds/:id()/overmaken",
    meta: indexXcO8vXQcDGMeta || {},
    alias: indexXcO8vXQcDGMeta?.alias || [],
    redirect: indexXcO8vXQcDGMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/mogelijk-fonds/[id]/overmaken/index.vue").then(m => m.default || m)
  },
  {
    name: index2rUv29cehyMeta?.name ?? "mogelijk-fonds",
    path: index2rUv29cehyMeta?.path ?? "/mogelijk-fonds",
    meta: index2rUv29cehyMeta || {},
    alias: index2rUv29cehyMeta?.alias || [],
    redirect: index2rUv29cehyMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/mogelijk-fonds/index.vue").then(m => m.default || m)
  },
  {
    name: errorV94aXjeAt4Meta?.name ?? "ockto-hash-error",
    path: errorV94aXjeAt4Meta?.path ?? "/ockto/:hash()/error",
    meta: errorV94aXjeAt4Meta || {},
    alias: errorV94aXjeAt4Meta?.alias || [],
    redirect: errorV94aXjeAt4Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/ockto/[hash]/error.vue").then(m => m.default || m)
  },
  {
    name: indexXE2fGpq6b3Meta?.name ?? "ockto-hash",
    path: indexXE2fGpq6b3Meta?.path ?? "/ockto/:hash()",
    meta: indexXE2fGpq6b3Meta || {},
    alias: indexXE2fGpq6b3Meta?.alias || [],
    redirect: indexXE2fGpq6b3Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/ockto/[hash]/index.vue").then(m => m.default || m)
  },
  {
    name: qrcodefBsFCWO26gMeta?.name ?? "ockto-hash-qrcode",
    path: qrcodefBsFCWO26gMeta?.path ?? "/ockto/:hash()/qrcode",
    meta: qrcodefBsFCWO26gMeta || {},
    alias: qrcodefBsFCWO26gMeta?.alias || [],
    redirect: qrcodefBsFCWO26gMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/ockto/[hash]/qrcode.vue").then(m => m.default || m)
  },
  {
    name: succesVIDiQ80mIhMeta?.name ?? "ockto-hash-succes",
    path: succesVIDiQ80mIhMeta?.path ?? "/ockto/:hash()/succes",
    meta: succesVIDiQ80mIhMeta || {},
    alias: succesVIDiQ80mIhMeta?.alias || [],
    redirect: succesVIDiQ80mIhMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/ockto/[hash]/succes.vue").then(m => m.default || m)
  },
  {
    name: _91token_93wnVJg49iDRMeta?.name ?? "onboarding-signing_id-token",
    path: _91token_93wnVJg49iDRMeta?.path ?? "/onboarding/:signing_id()/:token()",
    meta: _91token_93wnVJg49iDRMeta || {},
    alias: _91token_93wnVJg49iDRMeta?.alias || [],
    redirect: _91token_93wnVJg49iDRMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/onboarding/[signing_id]/[token].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93TMdVpEilfTMeta?.name ?? "onboarding-slug",
    path: _91slug_93TMdVpEilfTMeta?.path ?? "/onboarding/:slug()",
    meta: _91slug_93TMdVpEilfTMeta || {},
    alias: _91slug_93TMdVpEilfTMeta?.alias || [],
    redirect: _91slug_93TMdVpEilfTMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/onboarding/[slug].vue").then(m => m.default || m)
  },
  {
    name: aanvraagE6PhZ5QZ7dMeta?.name ?? "onboarding-aanvraag",
    path: aanvraagE6PhZ5QZ7dMeta?.path ?? "/onboarding/aanvraag",
    meta: aanvraagE6PhZ5QZ7dMeta || {},
    alias: aanvraagE6PhZ5QZ7dMeta?.alias || [],
    redirect: aanvraagE6PhZ5QZ7dMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/onboarding/aanvraag.vue").then(m => m.default || m)
  },
  {
    name: _91experience_93dCXXeumJXmMeta?.name ?? "onboarding-bedankt-experience",
    path: _91experience_93dCXXeumJXmMeta?.path ?? "/onboarding/bedankt/:experience()",
    meta: _91experience_93dCXXeumJXmMeta || {},
    alias: _91experience_93dCXXeumJXmMeta?.alias || [],
    redirect: _91experience_93dCXXeumJXmMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/onboarding/bedankt/[experience].vue").then(m => m.default || m)
  },
  {
    name: _91token_93kVj7tYES6YMeta?.name ?? "onfido-token",
    path: _91token_93kVj7tYES6YMeta?.path ?? "/onfido/:token()",
    meta: _91token_93kVj7tYES6YMeta || {},
    alias: _91token_93kVj7tYES6YMeta?.alias || [],
    redirect: _91token_93kVj7tYES6YMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/onfido/[token].vue").then(m => m.default || m)
  },
  {
    name: _91hash_93e3AeVPAQXsMeta?.name ?? "passeren-hash",
    path: _91hash_93e3AeVPAQXsMeta?.path ?? "/passeren/:hash()",
    meta: _91hash_93e3AeVPAQXsMeta || {},
    alias: _91hash_93e3AeVPAQXsMeta?.alias || [],
    redirect: _91hash_93e3AeVPAQXsMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/passeren/[hash].vue").then(m => m.default || m)
  },
  {
    name: indexHwHfFf4WDrMeta?.name ?? "privacy-verklaring",
    path: indexHwHfFf4WDrMeta?.path ?? "/privacy-verklaring",
    meta: indexHwHfFf4WDrMeta || {},
    alias: indexHwHfFf4WDrMeta?.alias || [],
    redirect: indexHwHfFf4WDrMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/privacy-verklaring/index.vue").then(m => m.default || m)
  },
  {
    name: _91hash_93cOK3iYa7sEMeta?.name ?? "rentevoorstel-hash",
    path: _91hash_93cOK3iYa7sEMeta?.path ?? "/rentevoorstel/:hash()",
    meta: _91hash_93cOK3iYa7sEMeta || {},
    alias: _91hash_93cOK3iYa7sEMeta?.alias || [],
    redirect: _91hash_93cOK3iYa7sEMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/rentevoorstel/[hash].vue").then(m => m.default || m)
  },
  {
    name: _91fundKey_93sBiu8yXXGeMeta?.name ?? "sso-exchangeToken-fundKey",
    path: _91fundKey_93sBiu8yXXGeMeta?.path ?? "/sso/:exchangeToken()/:fundKey()",
    meta: _91fundKey_93sBiu8yXXGeMeta || {},
    alias: _91fundKey_93sBiu8yXXGeMeta?.alias || [],
    redirect: _91fundKey_93sBiu8yXXGeMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/sso/[exchangeToken]/[fundKey].vue").then(m => m.default || m)
  },
  {
    name: aanvraag3EB278SSkFMeta?.name ?? "sso-exchangeToken-onboarding-aanvraag",
    path: aanvraag3EB278SSkFMeta?.path ?? "/sso/:exchangeToken()/onboarding/aanvraag",
    meta: aanvraag3EB278SSkFMeta || {},
    alias: aanvraag3EB278SSkFMeta?.alias || [],
    redirect: aanvraag3EB278SSkFMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/sso/[exchangeToken]/onboarding/aanvraag.vue").then(m => m.default || m)
  },
  {
    name: indexzgTcSifIEYMeta?.name ?? "sso-exchangeToken-onboarding",
    path: indexzgTcSifIEYMeta?.path ?? "/sso/:exchangeToken()/onboarding",
    meta: indexzgTcSifIEYMeta || {},
    alias: indexzgTcSifIEYMeta?.alias || [],
    redirect: indexzgTcSifIEYMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/sso/[exchangeToken]/onboarding/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93gaSF8k5pn5Meta?.name ?? "sso-token-id",
    path: _91id_93gaSF8k5pn5Meta?.path ?? "/sso/:token()/:id()",
    meta: _91id_93gaSF8k5pn5Meta || {},
    alias: _91id_93gaSF8k5pn5Meta?.alias || [],
    redirect: _91id_93gaSF8k5pn5Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/sso/[token]/[id].vue").then(m => m.default || m)
  },
  {
    name: indexKRagKrAcR8Meta?.name ?? "sso-token",
    path: indexKRagKrAcR8Meta?.path ?? "/sso/:token()",
    meta: indexKRagKrAcR8Meta || {},
    alias: indexKRagKrAcR8Meta?.alias || [],
    redirect: indexKRagKrAcR8Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/sso/[token]/index.vue").then(m => m.default || m)
  },
  {
    name: _91hash_93a4ISHvH2iCMeta?.name ?? "verzekeringen-hash",
    path: _91hash_93a4ISHvH2iCMeta?.path ?? "/verzekeringen/:hash()",
    meta: _91hash_93a4ISHvH2iCMeta || {},
    alias: _91hash_93a4ISHvH2iCMeta?.alias || [],
    redirect: _91hash_93a4ISHvH2iCMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403135050/packages/investors/pages/verzekeringen/[hash].vue").then(m => m.default || m)
  }
]