import {
  faAngleDown,
  faArrowUpRightFromSquare,
  faXmark,
  faCheck,
  faChevronDown,
  faChevronUp,
  faBuildingLock,
  faPlus,
  faQuestion,
  faStar,
  faSpinner,
  faTimer,
  faTrashCan,
  faPen,
  faCircleExclamation,
  faObjectsColumn,
  faDiagramCells,
  faGear,
  faAngleLeft,
  faAngleRight,
  faFiles as fasFiles,
  faChartMixed as fasChartMixed,
  faChartLineUp as fasChartLineUp,
  faHandHoldingDollar as fasHandHoldingDollar,
  faDisplayChartUp as fasDisplayChartUp,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faAnglesLeft,
  faAnglesRight,
  faBars,
  faBriefcase,
  faBuilding,
  faClock,
  faDownload,
  faEllipsis,
  faFile,
  faFilePdf,
  faFileArrowDown,
  faLoader,
  faPercent,
  faSack,
  faSquareExclamation,
  faThumbsUp,
  faUser,
  faRoute,
  faEuroSign,
} from '@fortawesome/pro-duotone-svg-icons';
import {
  faCircleInfo as falCircleInfo,
  faFiles as falFiles,
  faChartMixed as falChartMixed,
  faChartLineUp as falChartLineUp,
  faHandHoldingDollar as falHandHoldingDollar,
  faDisplayChartUp as falDisplayChartUp,
  faCog as falCog,
} from '@fortawesome/pro-light-svg-icons';

export const getFontAwesomeIcons = () => [
  faAngleDown,
  faAnglesLeft,
  faAnglesRight,
  faArrowUpRightFromSquare,
  faBars,
  faBriefcase,
  faBuilding,
  faCheck,
  faChevronDown,
  faChevronUp,
  faClock,
  faDownload,
  faEllipsis,
  faFile,
  faFilePdf,
  faFileArrowDown,
  faLoader,
  faPlus,
  faSquareExclamation,
  faCircleExclamation,
  faRoute,
  faPercent,
  faSack,
  faThumbsUp,
  faUser,
  faXmark,
  faQuestion,
  faBuildingLock,
  faStar,
  faTimer,
  faTrashCan,
  faSpinner,
  faPen,
  faObjectsColumn,
  faDiagramCells,
  faGear,
  faEuroSign,
  faAngleLeft,
  faAngleRight,
  falCircleInfo,
  fasFiles,
  fasChartMixed,
  fasChartLineUp,
  fasHandHoldingDollar,
  fasDisplayChartUp,
  falFiles,
  falChartMixed,
  falChartLineUp,
  falHandHoldingDollar,
  falDisplayChartUp,
  falCog,
  fasFiles,
  fasChartMixed,
  fasChartLineUp,
  fasHandHoldingDollar,
  fasDisplayChartUp,
];
